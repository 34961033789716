import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import Layout from "../components/layout";
import Tick from "../assets/tick.svg"
import Stars from '../components/stars';
import Troy from "../assets/troy-highquality.jpg"
import MobileBanner from "../components/mobile-banner"
import { SEO } from "../components/seo"

const About = () => {
    return (
        <Layout>
            <Container className="p-0 pdrm-sub__hero--wrapper">
            <MobileBanner pathName={Troy}></MobileBanner>
                <Container className="pdrm-sub__hero p-0">
                    <Row className="pdrm-sub__hero__row g-0">
                        <Col xl={10} sm={12}>
                        <div className="pdrm-sub__hero__text-wrapper">
                            <Stars></Stars>
                            <h1>About Patriot</h1>
                            <p>Patriot dent removal and mechanical is a veteran family owned local business located in the Tamworth area.
                            <br></br><br></br>With over 13 years experience in mechanical and 5 years experience in Paintless Dent repairs, we take pride in delivering an efficient and quality repair service which you can trust. We have a passion for working on classic vehicles, either mechanical or dent repairs. Where most dent guys won’t touch classic cars, we will always have a go at fixing them.
                            
                            <br></br><br></br>For your next ding, dents, scrape or mechanical repair, contact us on 0407348941 for a quote or to book in a service. </p>
                            <Button href="tel:0407348941" className="pdrm-button secondary">Get a quote</Button>  
                        </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="pdrm-sub__banner">
                <Container fluid className="pdrm-sub__pros">
                    <Row className="pdrm-sub__pros--about">
                        <h1>Why Choose Patriot?</h1>
                        <Col lg={3}>
                            <div className="m-1 col-card">
                            <img src={Tick} width="50px" alt="Tick"></img>
                            <h2>Mobile Repairs</h2>
                            <p>Trouble free paint, dent or mechnanical repair at the convenience of your own home or workplace.</p>
                            </div>
                        </Col>
                        <Col lg={3}>
                        <div className="m-1 col-card">
                            <img src={Tick} width="50px" alt="Tick"></img>
                            <h2>Attention to Detail</h2>
                            <p>Experienced service delivered craftsmanship and care.</p>
                            </div>

                        </Col>
                        <Col lg={3}>
                        <div className="m-1 col-card">
                            <img src={Tick} width="50px" alt="Tick"></img>
                            <h2>Fast turn around</h2>
                            <p>Quick turn around times for our PDR repairs.</p>
                            </div>

                        </Col>
                        <Col lg={3}>
                        <div className="m-1 col-card">
                            <img src={Tick} width="50px" alt="Tick"></img>
                            <h2>Affordable</h2>
                            <p>Paintless dent repair can cost a fraction compared to traditional panel repair.</p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </Container>
        </Layout>
    )
};
export default About;

export const Head = () => (
    <SEO />
  )