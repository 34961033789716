import React from 'react';
import Star from "../assets/star.svg";

const Stars = () => {
    return (
       <>
        <img src={Star} width="30px" height="30px" alt="star" className="pdrm__hero-star"></img>
        <img src={Star} width="30px" height="30px" alt="star" className="pdrm__hero-star"></img>
        <img src={Star} width="30px" height="30px" alt="star" className="pdrm__hero-star"></img>
       </>
    )
};
export default Stars;