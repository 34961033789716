import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

const MobileBanner = ({pathName}) => {
    const [show, setShow] = useState();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setShow(window.innerWidth < 576)
        }
    })

    return (
        <> {
            show && 
            <Container fluid style={{backgroundImage: `url('${pathName}')`, minHeight: "300px", backgroundPosition: "50%", backgroundSize: "cover"}}>
            </Container>
            }
        </>
    )
};
export default MobileBanner;